import { SvelteComponent, init, safe_not_equal, noop, listen, run_all, component_subscribe, globals, set_store_value, is_function, subscribe, create_component, space, empty, mount_component, insert, transition_in, group_outros, transition_out, check_outros, destroy_component, detach, binding_callbacks, element, attr, bubble } from 'svelte/internal';
import { getContext } from 'svelte';
import { ancestor, addDuration, cloneDate, addDay, rect, toEventWithLocalDates, toViewWithLocalDates, cloneEvent, assign, createDuration } from '@event-calendar/common';

function traverseTimeGrid(el) {
    let dayEl = ancestor(el, 2);
    let colEl = child(ancestor(dayEl, 1), 1);
    let bodyEl = ancestor(dayEl, 3);
    let col = pos(dayEl) - 1;

    return [colEl, bodyEl, col];
}

function traverseResourceTimeGrid(el, datesAboveResources) {
    let dayEl = ancestor(el, 2);
    let colEl = child(child(ancestor(dayEl, 2), 1), 0);
    let resourceEl = ancestor(dayEl, 1);
    let bodyEl = ancestor(resourceEl, 3);
    let col = pos(dayEl);
    let resourceCol = pos(resourceEl) - 1;

    return datesAboveResources ? [colEl, bodyEl, resourceCol, col] : [colEl, bodyEl, col, resourceCol];
}

function traverseDayGrid(el, inPopup) {
    let dayEl = ancestor(el, inPopup ? 3 : 2);
    let daysEl = ancestor(dayEl, 1);
    let contentEl = ancestor(daysEl, 1);
    let colEl = child(child(contentEl, 0), 0);
    let bodyEl = ancestor(contentEl, 1);
    let col = pos(dayEl);
    let row = pos(daysEl);

    return [colEl, bodyEl, col, row, contentEl.children];
}

function child(el, pos) {
    return el.children[pos];
}

function pos(el) {
    let result = 0;
    while ((el = el.previousElementSibling)) {
        ++result;
    }
    return result;
}

let busy = false;
function animate(fn) {
    if (!busy) {
        busy = true;
        window.requestAnimationFrame(() => {
            fn();
            busy = false;
        });
    }
}

function limit(value, max) {
    return Math.max(0, Math.min(max, value));
}

function floor(value) {
    return Math.floor(value);
}

/* packages/interaction/src/Drag.svelte generated by Svelte v3.49.0 */

const { window: window_1 } = globals;

function create_fragment$3(ctx) {
	let mounted;
	let dispose;

	return {
		c: noop,
		m(target, anchor) {
			if (!mounted) {
				dispose = [
					listen(window_1, "pointermove", /*handlePointerMove*/ ctx[19]),
					listen(window_1, "pointerup", /*handlePointerUp*/ ctx[20]),
					listen(window_1, "selectstart", /*handleSelectStart*/ ctx[21]),
					listen(window_1, "scroll", /*handleScroll*/ ctx[0])
				];

				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			mounted = false;
			run_all(dispose);
		}
	};
}

const INTERACTING_TIME_GRID = 1;
const INTERACTING_DAY_GRID = 2;

function instance$3($$self, $$props, $$invalidate) {
	let $_events;
	let $_iEvents;
	let $_view;
	let $_iClass;

	let $_viewResources,
		$$unsubscribe__viewResources = noop,
		$$subscribe__viewResources = () => ($$unsubscribe__viewResources(), $$unsubscribe__viewResources = subscribe(_viewResources, $$value => $$invalidate(49, $_viewResources = $$value)), _viewResources);

	let $eventDrop;
	let $eventResize;
	let $eventDragStop;
	let $eventResizeStop;
	let $dragScroll;
	let $eventDragStart;
	let $eventResizeStart;
	let $eventDragMinDistance;
	let $_viewDates;
	let $hiddenDays;
	let $slotDuration;
	let $slotHeight;
	let $datesAboveResources;
	$$self.$$.on_destroy.push(() => $$unsubscribe__viewResources());
	let { _iEvents, _iClass, _events, _viewDates, editable, eventStartEditable, eventDragMinDistance, eventDragStart, eventDragStop, eventDrop, eventResizeStart, eventResizeStop, eventResize, dragScroll, slotDuration, slotHeight, hiddenDays, _view, datesAboveResources } = getContext('state');
	component_subscribe($$self, _iEvents, value => $$invalidate(46, $_iEvents = value));
	component_subscribe($$self, _iClass, value => $$invalidate(48, $_iClass = value));
	component_subscribe($$self, _events, value => $$invalidate(45, $_events = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(58, $_viewDates = value));
	component_subscribe($$self, eventDragMinDistance, value => $$invalidate(57, $eventDragMinDistance = value));
	component_subscribe($$self, eventDragStart, value => $$invalidate(55, $eventDragStart = value));
	component_subscribe($$self, eventDragStop, value => $$invalidate(52, $eventDragStop = value));
	component_subscribe($$self, eventDrop, value => $$invalidate(50, $eventDrop = value));
	component_subscribe($$self, eventResizeStart, value => $$invalidate(56, $eventResizeStart = value));
	component_subscribe($$self, eventResizeStop, value => $$invalidate(53, $eventResizeStop = value));
	component_subscribe($$self, eventResize, value => $$invalidate(51, $eventResize = value));
	component_subscribe($$self, dragScroll, value => $$invalidate(54, $dragScroll = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(60, $slotDuration = value));
	component_subscribe($$self, slotHeight, value => $$invalidate(61, $slotHeight = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(59, $hiddenDays = value));
	component_subscribe($$self, _view, value => $$invalidate(47, $_view = value));
	component_subscribe($$self, datesAboveResources, value => $$invalidate(62, $datesAboveResources = value));
	let interacting = false;
	let resizing; // whether it is resizing
	let event;
	let col, row;
	let offsetX, offsetY;
	let fromX, fromY;
	let toX, toY;
	let delta;
	let colEl, rowEls, bodyEl;
	let colRect, bodyRect;
	let _viewResources;
	let resourceCol, newResourceCol;
	let isAllDay;
	let minEnd; // minimum end time when resizing

	function startTimeGrid(event, el, jsEvent, resourcesStore, allDay, resize) {
		if (!interacting && jsEvent.isPrimary) {
			if (resourcesStore) {
				[colEl, bodyEl, col, resourceCol] = traverseResourceTimeGrid(el, $datesAboveResources);
			} else {
				[colEl, bodyEl, col] = traverseTimeGrid(el);
			}

			start(event, jsEvent);
			offsetY = floor((jsEvent.clientY - colRect.top) / $slotHeight);
			offsetX = 0; // applicable for all-day slot

			if (allDay && (!resourcesStore || !$datesAboveResources)) {
				offsetX = floor((jsEvent.clientX - colRect.left) / colRect.width) - col - (resourceCol || 0) * $_viewDates.length;
			}

			$$subscribe__viewResources($$invalidate(1, _viewResources = resourcesStore));
			interacting = INTERACTING_TIME_GRID;
			isAllDay = allDay;
			resizing = resize;

			if (resizing) {
				minEnd = addDuration(cloneDate(event.start), $slotDuration);
				set_store_value(_iClass, $_iClass = 'resizingY', $_iClass);
			}
		}
	}

	function startDayGrid(event, el, jsEvent, inPopup, resize) {
		if (!interacting && jsEvent.isPrimary) {
			[colEl, bodyEl, col, row, rowEls] = traverseDayGrid(el, inPopup);
			start(event, jsEvent);

			offsetX = inPopup
			? 0
			: floor((jsEvent.clientX - colRect.left) / colRect.width) - col;

			$$subscribe__viewResources($$invalidate(1, _viewResources = undefined));
			interacting = INTERACTING_DAY_GRID;
			isAllDay = false;
			resizing = resize;

			if (resizing) {
				minEnd = cloneDate(event.start);
				minEnd.setUTCHours(event.end.getUTCHours(), event.end.getUTCMinutes(), event.end.getUTCSeconds());

				if (minEnd < event.start) {
					addDay(minEnd);
				} // minEnd = addDuration(cloneDate(event.start), $slotDuration);  alternative version

				set_store_value(_iClass, $_iClass = 'resizingX', $_iClass);
			}
		}
	}

	function start(eventToDrag, jsEvent) {
		window.getSelection().removeAllRanges();
		event = eventToDrag;
		colRect = rect(colEl);
		bodyRect = rect(bodyEl);
		fromX = toX = jsEvent.clientX;
		fromY = toY = jsEvent.clientY;
		set_store_value(_iClass, $_iClass = 'dragging', $_iClass);
	}

	function move(jsEvent) {
		let rx = toX - colRect.left;
		let ry = toY - colRect.top;
		let newCol = floor(rx / colRect.width);

		if (interacting === INTERACTING_TIME_GRID) {
			// timeGrid
			if (_viewResources) {
				if ($datesAboveResources) {
					let dayCol = limit(floor(newCol / $_viewResources.length), $_viewDates.length - 1);
					newResourceCol = limit(newCol - dayCol * $_viewResources.length, $_viewResources.length - 1);
					newCol = dayCol;
				} else {
					if (resizing) {
						newCol -= resourceCol * $_viewDates.length;
					} else {
						newResourceCol = limit(floor(newCol / $_viewDates.length), $_viewResources.length - 1);
						newCol -= newResourceCol * $_viewDates.length;
					}
				}
			}

			newCol = limit(newCol, $_viewDates.length - 1);

			delta = createDuration({
				days: ($_viewDates[newCol] - $_viewDates[col]) / 1000 / 60 / 60 / 24 - offsetX,
				seconds: isAllDay
				? 0
				: (floor(ry / $slotHeight) - offsetY) * $slotDuration.seconds
			});
		} else {
			// dayGrid
			let cols = 7 - $hiddenDays.length;

			newCol = limit(newCol, cols - 1);
			let newRow = -1;

			do {
				++newRow;
				ry -= rowEls[newRow].offsetHeight;
			} while (ry > 0 && newRow < rowEls.length - 1);

			delta = createDuration({
				days: ($_viewDates[newRow * cols + newCol] - $_viewDates[row * cols + col]) / 1000 / 60 / 60 / 24 - offsetX
			});
		}

		if ($_iEvents[0] || resizing || distance() >= $eventDragMinDistance) {
			if (!$_iEvents[0]) {
				createIEvent(jsEvent, resizing ? $eventResizeStart : $eventDragStart);
			}

			set_store_value(_iEvents, $_iEvents[0].end = addDuration(cloneDate(event.end), delta), $_iEvents);

			if (resizing) {
				if ($_iEvents[0].end < minEnd) {
					set_store_value(_iEvents, $_iEvents[0].end = minEnd, $_iEvents);
					delta = createDuration(($_iEvents[0].end - event.end) / 1000);
				}
			} else {
				set_store_value(_iEvents, $_iEvents[0].start = addDuration(cloneDate(event.start), delta), $_iEvents);

				if (_viewResources) {
					set_store_value(_iEvents, $_iEvents[0].resourceIds = event.resourceIds.filter(id => id !== $_viewResources[resourceCol].id), $_iEvents);
					$_iEvents[0].resourceIds.push($_viewResources[newResourceCol].id);
				}
			}
		}

		if ($dragScroll) {
			animate(() => {
				if (toY < 0) {
					window.scrollBy(0, Math.max(-10, toY / 3));
				}

				if (toY < bodyRect.top) {
					bodyEl.scrollTop += Math.max(-10, (toY - bodyRect.top) / 3);
				}

				if (toY > window.innerHeight) {
					window.scrollBy(0, Math.min(10, (toY - window.innerHeight) / 3));
				}

				if (toY > bodyRect.bottom) {
					bodyEl.scrollTop += Math.min(10, (toY - bodyRect.bottom) / 3);
				}
			});
		}
	}

	function handleScroll() {
		if (interacting) {
			colRect = rect(colEl);
			bodyRect = rect(bodyEl);
			move();
		}
	}

	function handlePointerMove(jsEvent) {
		if (interacting && jsEvent.isPrimary) {
			toX = jsEvent.clientX;
			toY = jsEvent.clientY;
			move(jsEvent);
		}
	}

	function handlePointerUp(jsEvent) {
		if (interacting && jsEvent.isPrimary) {
			if ($_iEvents[0]) {
				event.display = 'auto';
				let callback = resizing ? $eventResizeStop : $eventDragStop;

				if (is_function(callback)) {
					callback({
						event: toEventWithLocalDates(event),
						jsEvent,
						view: toViewWithLocalDates($_view)
					});
				}

				let oldEvent = cloneEvent(event);
				updateEvent(event, $_iEvents[0]);
				set_store_value(_iEvents, $_iEvents[0] = null, $_iEvents);
				callback = resizing ? $eventResize : $eventDrop;

				if (is_function(callback)) {
					let eventRef = event;

					let info = resizing
					? { endDelta: delta }
					: {
							delta,
							oldResource: resourceCol !== newResourceCol
							? $_viewResources[resourceCol]
							: undefined,
							newResource: resourceCol !== newResourceCol
							? $_viewResources[newResourceCol]
							: undefined
						};

					callback(assign(info, {
						event: toEventWithLocalDates(event),
						oldEvent: toEventWithLocalDates(oldEvent),
						jsEvent,
						view: toViewWithLocalDates($_view),
						revert() {
							updateEvent(eventRef, oldEvent);
						}
					}));
				}
			}

			colEl = rowEls = bodyEl = null;
			resourceCol = newResourceCol = undefined;
			interacting = false;
			set_store_value(_iClass, $_iClass = undefined, $_iClass);
		}
	}

	function createIEvent(jsEvent, callback) {
		if (is_function(callback)) {
			callback({
				event: toEventWithLocalDates(event),
				jsEvent,
				view: toViewWithLocalDates($_view)
			});
		}

		event.display = 'preview';
		set_store_value(_iEvents, $_iEvents[0] = cloneEvent(event), $_iEvents);
		event.display = 'ghost';
		_events.set($_events);
	}

	function updateEvent(target, source) {
		target.start = source.start;
		target.end = source.end;
		target.resourceIds = source.resourceIds;
		_events.set($_events);
	}

	function distance() {
		return Math.sqrt(Math.pow(toX - fromX, 2) + Math.pow(toY - fromY, 2));
	}

	function handleSelectStart(jsEvent) {
		if (interacting) {
			jsEvent.preventDefault();
		}
	}

	return [
		handleScroll,
		_viewResources,
		_iEvents,
		_iClass,
		_events,
		_viewDates,
		eventDragMinDistance,
		eventDragStart,
		eventDragStop,
		eventDrop,
		eventResizeStart,
		eventResizeStop,
		eventResize,
		dragScroll,
		slotDuration,
		slotHeight,
		hiddenDays,
		_view,
		datesAboveResources,
		handlePointerMove,
		handlePointerUp,
		handleSelectStart,
		startTimeGrid,
		startDayGrid
	];
}

class Drag extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$3,
			create_fragment$3,
			safe_not_equal,
			{
				startTimeGrid: 22,
				startDayGrid: 23,
				handleScroll: 0
			},
			null,
			[-1, -1, -1]
		);
	}

	get startTimeGrid() {
		return this.$$.ctx[22];
	}

	get startDayGrid() {
		return this.$$.ctx[23];
	}

	get handleScroll() {
		return this.$$.ctx[0];
	}
}

/* packages/interaction/src/Pointer.svelte generated by Svelte v3.49.0 */

function create_fragment$2(ctx) {
	let mounted;
	let dispose;

	return {
		c: noop,
		m(target, anchor) {
			if (!mounted) {
				dispose = [
					listen(window, "pointermove", /*handlePointerMove*/ ctx[5]),
					listen(window, "scroll", /*handleScroll*/ ctx[0])
				];

				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			mounted = false;
			run_all(dispose);
		}
	};
}

function validEvent(jsEvent) {
	return jsEvent.isPrimary && jsEvent.pointerType === 'mouse';
}

function instance$2($$self, $$props, $$invalidate) {
	let $_iEvents;
	let $slotDuration;

	let $_slotTimeLimits,
		$$unsubscribe__slotTimeLimits = noop,
		$$subscribe__slotTimeLimits = () => ($$unsubscribe__slotTimeLimits(), $$unsubscribe__slotTimeLimits = subscribe(_slotTimeLimits, $$value => $$invalidate(17, $_slotTimeLimits = $$value)), _slotTimeLimits);

	let $slotHeight;
	$$self.$$.on_destroy.push(() => $$unsubscribe__slotTimeLimits());
	let { _iEvents, _events, _viewDates, slotDuration, slotHeight, hiddenDays, _view, datesAboveResources, theme } = getContext('state');
	component_subscribe($$self, _iEvents, value => $$invalidate(9, $_iEvents = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(16, $slotDuration = value));
	component_subscribe($$self, slotHeight, value => $$invalidate(18, $slotHeight = value));
	let y;
	let colDate;
	let colEl;
	let colRect;
	let resource;
	let _slotTimeLimits;
	let date;

	function enterTimeGrid(date, el, jsEvent, slotTimeLimitsStore, resourceObj) {
		if (validEvent(jsEvent)) {
			colDate = date;
			colEl = el;
			colRect = rect(colEl);
			y = jsEvent.clientY;
			$$subscribe__slotTimeLimits($$invalidate(1, _slotTimeLimits = slotTimeLimitsStore));
			resource = resourceObj;
		}
	}

	function enterDayGrid(date, jsEvent) {
		if (validEvent(jsEvent)) {
			colDate = date;
			colEl = null;
			y = $$subscribe__slotTimeLimits($$invalidate(1, _slotTimeLimits = resource = undefined));
		}
	}

	function leave(jsEvent) {
		if (validEvent(jsEvent)) {
			removePointerEvent();
		}
	}

	function move() {
		if (!colDate) {
			return;
		}

		if (colEl) {
			// timeGrid
			let ry = y - colRect.top;

			date = addDuration(cloneDate(colDate), $slotDuration, floor(ry / $slotHeight + $_slotTimeLimits.min.seconds / $slotDuration.seconds));
		} else {
			// dayGrid
			date = colDate;
		}

		if (!$_iEvents[1]) {
			createPointerEvent();
		}

		set_store_value(_iEvents, $_iEvents[1].start = date, $_iEvents);
		set_store_value(_iEvents, $_iEvents[1].end = addDuration(cloneDate(date), $slotDuration), $_iEvents);
		set_store_value(_iEvents, $_iEvents[1].resourceIds = resource ? [resource.id] : [], $_iEvents);
	}

	function handleScroll() {
		if (colEl) {
			colRect = rect(colEl);
			move();
		}
	}

	function handlePointerMove(jsEvent) {
		if (validEvent(jsEvent)) {
			y = jsEvent.clientY;
			move();
		}
	}

	function createPointerEvent() {
		set_store_value(
			_iEvents,
			$_iEvents[1] = {
				id: '{pointer}',
				title: '',
				display: 'pointer',
				extendedProps: {},
				backgroundColor: 'transparent'
			},
			$_iEvents
		);
	}

	function removePointerEvent() {
		colDate = colEl = set_store_value(_iEvents, $_iEvents[1] = null, $_iEvents);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_iEvents*/ 512) {
			if ($_iEvents[0]) {
				removePointerEvent();
			}
		}
	};

	return [
		handleScroll,
		_slotTimeLimits,
		_iEvents,
		slotDuration,
		slotHeight,
		handlePointerMove,
		enterTimeGrid,
		enterDayGrid,
		leave,
		$_iEvents
	];
}

class Pointer extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$2, create_fragment$2, safe_not_equal, {
			enterTimeGrid: 6,
			enterDayGrid: 7,
			leave: 8,
			handleScroll: 0
		});
	}

	get enterTimeGrid() {
		return this.$$.ctx[6];
	}

	get enterDayGrid() {
		return this.$$.ctx[7];
	}

	get leave() {
		return this.$$.ctx[8];
	}

	get handleScroll() {
		return this.$$.ctx[0];
	}
}

/* packages/interaction/src/Interaction.svelte generated by Svelte v3.49.0 */

function create_if_block$1(ctx) {
	let pointer_1;
	let current;
	let pointer_1_props = {};
	pointer_1 = new Pointer({ props: pointer_1_props });
	/*pointer_1_binding*/ ctx[18](pointer_1);

	return {
		c() {
			create_component(pointer_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(pointer_1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const pointer_1_changes = {};
			pointer_1.$set(pointer_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(pointer_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pointer_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			/*pointer_1_binding*/ ctx[18](null);
			destroy_component(pointer_1, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let drag;
	let t;
	let if_block_anchor;
	let current;
	let drag_props = {};
	drag = new Drag({ props: drag_props });
	/*drag_binding*/ ctx[17](drag);
	let if_block = /*$pointer*/ ctx[1] && create_if_block$1(ctx);

	return {
		c() {
			create_component(drag.$$.fragment);
			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			mount_component(drag, target, anchor);
			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const drag_changes = {};
			drag.$set(drag_changes);

			if (/*$pointer*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$pointer*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block$1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(drag.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(drag.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			/*drag_binding*/ ctx[17](null);
			destroy_component(drag, detaching);
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $_interaction;
	let $_scroll;
	let $editable;
	let $eventDurationEditable;
	let $_resizable;
	let $eventStartEditable;
	let $_draggable;
	let $theme;
	let $_classes;
	let $pointer;
	let { theme, editable, eventStartEditable, eventDurationEditable, pointer, _interaction, _classes, _draggable, _resizable, _scroll } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(16, $theme = value));
	component_subscribe($$self, editable, value => $$invalidate(12, $editable = value));
	component_subscribe($$self, eventStartEditable, value => $$invalidate(14, $eventStartEditable = value));
	component_subscribe($$self, eventDurationEditable, value => $$invalidate(13, $eventDurationEditable = value));
	component_subscribe($$self, pointer, value => $$invalidate(1, $pointer = value));
	component_subscribe($$self, _interaction, value => $$invalidate(0, $_interaction = value));
	component_subscribe($$self, _classes, value => $$invalidate(21, $_classes = value));
	component_subscribe($$self, _draggable, value => $$invalidate(15, $_draggable = value));
	component_subscribe($$self, _resizable, value => $$invalidate(20, $_resizable = value));
	component_subscribe($$self, _scroll, value => $$invalidate(19, $_scroll = value));

	set_store_value(
		_scroll,
		$_scroll = () => {
			for (let component of Object.values($_interaction)) {
				component && component.handleScroll && component.handleScroll();
			}
		},
		$_scroll
	);

	function drag_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_interaction.drag = $$value;
			_interaction.set($_interaction);
		});
	}

	function pointer_1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_interaction.pointer = $$value;
			_interaction.set($_interaction);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$eventStartEditable, $editable*/ 20480) {
			set_store_value(_draggable, $_draggable = event => (event.startEditable ?? $eventStartEditable) || (event.editable ?? $editable), $_draggable);
		}

		if ($$self.$$.dirty & /*$_draggable, $theme*/ 98304) {
			set_store_value(
				_classes,
				$_classes = (className, event) => {
					switch (event.display) {
						case 'auto':
							return className + ($_draggable(event) ? ' ' + $theme.draggable : '');
						case 'ghost':
							return `${$theme.event} ${$theme.ghost}`;
						case 'preview':
							return `${$theme.event} ${$theme.preview}`;
						case 'pointer':
							return `${$theme.event} ${$theme.pointer}`;
						default:
							return className;
					}
				},
				$_classes
			);
		}

		if ($$self.$$.dirty & /*$eventDurationEditable, $editable*/ 12288) {
			set_store_value(_resizable, $_resizable = event => (event.durationEditable ?? $eventDurationEditable) || (event.editable ?? $editable), $_resizable);
		}
	};

	return [
		$_interaction,
		$pointer,
		theme,
		editable,
		eventStartEditable,
		eventDurationEditable,
		pointer,
		_interaction,
		_classes,
		_draggable,
		_resizable,
		_scroll,
		$editable,
		$eventDurationEditable,
		$eventStartEditable,
		$_draggable,
		$theme,
		drag_binding,
		pointer_1_binding
	];
}

class Interaction extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$1, safe_not_equal, {});
	}
}

/* packages/interaction/src/Resizer.svelte generated by Svelte v3.49.0 */

function create_if_block(ctx) {
	let div;
	let div_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].resizer);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = listen(div, "pointerdown", /*pointerdown_handler*/ ctx[5]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].resizer)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let show_if = /*event*/ ctx[0].display === 'auto' && /*$_resizable*/ ctx[1](/*event*/ ctx[0]);
	let if_block_anchor;
	let if_block = show_if && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*event, $_resizable*/ 3) show_if = /*event*/ ctx[0].display === 'auto' && /*$_resizable*/ ctx[1](/*event*/ ctx[0]);

			if (show_if) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_resizable;
	let $theme;
	let { event } = $$props;
	let { theme, _resizable } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	component_subscribe($$self, _resizable, value => $$invalidate(1, $_resizable = value));

	function pointerdown_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$props => {
		if ('event' in $$props) $$invalidate(0, event = $$props.event);
	};

	return [event, $_resizable, $theme, theme, _resizable, pointerdown_handler];
}

class Resizer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { event: 0 });
	}
}

var index = {
	createOptions(options) {
		options.editable = false;
		options.eventStartEditable = true;
		options.eventDragMinDistance = 5;
		options.eventDragStart = undefined;
		options.eventDragStop = undefined;
		options.eventDrop = undefined;
		options.eventDurationEditable = true;
		options.eventResizeStart = undefined;
		options.eventResizeStop = undefined;
		options.eventResize = undefined;
		options.dragScroll = true;
		options.pointer = false;
		options.theme.draggable = 'ec-draggable';
		options.theme.ghost = 'ec-ghost';
		options.theme.preview = 'ec-preview';
		options.theme.pointer = 'ec-pointer';
		options.theme.resizer = 'ec-resizer';
		options.theme.dragging = 'ec-dragging';
		options.theme.resizingY = 'ec-resizing-y';
		options.theme.resizingX = 'ec-resizing-x';
	},

	createStores(state) {
		state._interaction.set({
			component: Interaction,
			resizer: Resizer
		});
	}
};

export { index as default };
