import { SvelteComponent, init, safe_not_equal, create_component, space, mount_component, insert, transition_in, group_outros, transition_out, check_outros, destroy_component, detach, component_subscribe, set_store_value, empty, destroy_each, element, text, attr, append, set_data } from 'svelte/internal';
import { getContext, setContext } from 'svelte';
import { State as State$1, Header, Body, Week, Day } from '@event-calendar/time-grid';
import { derived } from 'svelte/store';

function viewResources(state) {
    return derived(
        [state.resources, state.filterResourcesWithEvents, state._events, state._activeRange],
        ([$resources, $filterResourcesWithEvents, $_events, $_activeRange]) => {
            let result = $resources;

            if ($filterResourcesWithEvents) {
                result = $resources.filter(resource => {
                    for (let event of $_events) {
                        if (
                            event.display !== 'background' &&
                            event.resourceIds.includes(resource.id) &&
                            event.start < $_activeRange.end &&
                            event.end > $_activeRange.start
                        ) {
                            return true;
                        }
                    }
                    return false;
                });
            }

            if (!result.length) {
                result = state.resources.parse([{}]);
            }

            return result;
        }
    );
}

class State extends State$1 {
    constructor(state) {
        super(state);

        this._viewResources = viewResources(state);
    }
}

/* packages/resource-time-grid/src/View.svelte generated by Svelte v3.49.0 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[18] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[21] = list[i];
	return child_ctx;
}

function get_each_context_4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[27] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[24] = list[i];
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[27] = list[i];
	return child_ctx;
}

function get_each_context_5(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[18] = list[i];
	return child_ctx;
}

function get_each_context_6(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[21] = list[i];
	return child_ctx;
}

// (31:3) {#if loops[1].length > 1}
function create_if_block_2(ctx) {
	let div;
	let div_class_value;
	let each_value_6 = /*loops*/ ctx[0][1];
	let each_blocks = [];

	for (let i = 0; i < each_value_6.length; i += 1) {
		each_blocks[i] = create_each_block_6(get_each_context_6(ctx, each_value_6, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", div_class_value = /*$theme*/ ctx[5].days);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, titles, loops*/ 35) {
				each_value_6 = /*loops*/ ctx[0][1];
				let i;

				for (i = 0; i < each_value_6.length; i += 1) {
					const child_ctx = get_each_context_6(ctx, each_value_6, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_6(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_6.length;
			}

			if (dirty[0] & /*$theme*/ 32 && div_class_value !== (div_class_value = /*$theme*/ ctx[5].days)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (33:5) {#each loops[1] as item1}
function create_each_block_6(ctx) {
	let div;
	let t_value = /*titles*/ ctx[1][1](/*item1*/ ctx[21]) + "";
	let t;
	let div_class_value;

	return {
		c() {
			div = element("div");
			t = text(t_value);
			attr(div, "class", div_class_value = /*$theme*/ ctx[5].day);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*titles, loops*/ 3 && t_value !== (t_value = /*titles*/ ctx[1][1](/*item1*/ ctx[21]) + "")) set_data(t, t_value);

			if (dirty[0] & /*$theme*/ 32 && div_class_value !== (div_class_value = /*$theme*/ ctx[5].day)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (28:1) {#each loops[0] as item0}
function create_each_block_5(ctx) {
	let div1;
	let div0;
	let t0_value = /*titles*/ ctx[1][0](/*item0*/ ctx[18]) + "";
	let t0;
	let div0_class_value;
	let t1;
	let t2;
	let div1_class_value;
	let if_block = /*loops*/ ctx[0][1].length > 1 && create_if_block_2(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[5].day);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[5].resource);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, t0);
			append(div1, t1);
			if (if_block) if_block.m(div1, null);
			append(div1, t2);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*titles, loops*/ 3 && t0_value !== (t0_value = /*titles*/ ctx[1][0](/*item0*/ ctx[18]) + "")) set_data(t0, t0_value);

			if (dirty[0] & /*$theme*/ 32 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[5].day)) {
				attr(div0, "class", div0_class_value);
			}

			if (/*loops*/ ctx[0][1].length > 1) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(div1, t2);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*$theme*/ 32 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[5].resource)) {
				attr(div1, "class", div1_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block) if_block.d();
		}
	};
}

// (27:0) <Header>
function create_default_slot_2(ctx) {
	let each_1_anchor;
	let each_value_5 = /*loops*/ ctx[0][0];
	let each_blocks = [];

	for (let i = 0; i < each_value_5.length; i += 1) {
		each_blocks[i] = create_each_block_5(get_each_context_5(ctx, each_value_5, i));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, loops, titles*/ 35) {
				each_value_5 = /*loops*/ ctx[0][0];
				let i;

				for (i = 0; i < each_value_5.length; i += 1) {
					const child_ctx = get_each_context_5(ctx, each_value_5, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_5(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_5.length;
			}
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (41:0) {#if $allDaySlot}
function create_if_block(ctx) {
	let header;
	let current;

	header = new Header({
			props: {
				allDay: true,
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(header.$$.fragment);
		},
		m(target, anchor) {
			mount_component(header, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const header_changes = {};

			if (dirty[0] & /*$_viewDates, $theme, $_viewResources, $datesAboveResources*/ 60 | dirty[1] & /*$$scope*/ 32) {
				header_changes.$$scope = { dirty, ctx };
			}

			header.$set(header_changes);
		},
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(header, detaching);
		}
	};
}

// (51:2) {:else}
function create_else_block(ctx) {
	let each_1_anchor;
	let current;
	let each_value_4 = /*$_viewResources*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value_4.length; i += 1) {
		each_blocks[i] = create_each_block_4(get_each_context_4(ctx, each_value_4, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, $_viewDates, $_viewResources*/ 44) {
				each_value_4 = /*$_viewResources*/ ctx[2];
				let i;

				for (i = 0; i < each_value_4.length; i += 1) {
					const child_ctx = get_each_context_4(ctx, each_value_4, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_4(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value_4.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_4.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (43:2) {#if $datesAboveResources}
function create_if_block_1(ctx) {
	let each_1_anchor;
	let current;
	let each_value_2 = /*$_viewDates*/ ctx[3];
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, $_viewResources, $_viewDates*/ 44) {
				each_value_2 = /*$_viewDates*/ ctx[3];
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value_2.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_2.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (52:3) {#each $_viewResources as resource}
function create_each_block_4(ctx) {
	let div;
	let week;
	let t;
	let div_class_value;
	let current;

	week = new Week({
			props: {
				dates: /*$_viewDates*/ ctx[3],
				resource: /*resource*/ ctx[27]
			}
		});

	return {
		c() {
			div = element("div");
			create_component(week.$$.fragment);
			t = space();
			attr(div, "class", div_class_value = /*$theme*/ ctx[5].resource);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(week, div, null);
			append(div, t);
			current = true;
		},
		p(ctx, dirty) {
			const week_changes = {};
			if (dirty[0] & /*$_viewDates*/ 8) week_changes.dates = /*$_viewDates*/ ctx[3];
			if (dirty[0] & /*$_viewResources*/ 4) week_changes.resource = /*resource*/ ctx[27];
			week.$set(week_changes);

			if (!current || dirty[0] & /*$theme*/ 32 && div_class_value !== (div_class_value = /*$theme*/ ctx[5].resource)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(week.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(week.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(week);
		}
	};
}

// (46:5) {#each $_viewResources as resource}
function create_each_block_3(ctx) {
	let week;
	let current;

	week = new Week({
			props: {
				dates: [/*date*/ ctx[24]],
				resource: /*resource*/ ctx[27]
			}
		});

	return {
		c() {
			create_component(week.$$.fragment);
		},
		m(target, anchor) {
			mount_component(week, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const week_changes = {};
			if (dirty[0] & /*$_viewDates*/ 8) week_changes.dates = [/*date*/ ctx[24]];
			if (dirty[0] & /*$_viewResources*/ 4) week_changes.resource = /*resource*/ ctx[27];
			week.$set(week_changes);
		},
		i(local) {
			if (current) return;
			transition_in(week.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(week.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(week, detaching);
		}
	};
}

// (44:3) {#each $_viewDates as date}
function create_each_block_2(ctx) {
	let div;
	let t;
	let div_class_value;
	let current;
	let each_value_3 = /*$_viewResources*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			attr(div, "class", div_class_value = /*$theme*/ ctx[5].resource);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			append(div, t);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_viewDates, $_viewResources*/ 12) {
				each_value_3 = /*$_viewResources*/ ctx[2];
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_3(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, t);
					}
				}

				group_outros();

				for (i = each_value_3.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 32 && div_class_value !== (div_class_value = /*$theme*/ ctx[5].resource)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_3.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (42:1) <Header allDay>
function create_default_slot_1(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_1, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$datesAboveResources*/ ctx[4]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (63:3) {#each loops[1] as item1}
function create_each_block_1(ctx) {
	let day;
	let current;

	day = new Day({
			props: {
				date: /*$datesAboveResources*/ ctx[4]
				? /*item0*/ ctx[18]
				: /*item1*/ ctx[21],
				resource: /*$datesAboveResources*/ ctx[4]
				? /*item1*/ ctx[21]
				: /*item0*/ ctx[18]
			}
		});

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const day_changes = {};

			if (dirty[0] & /*$datesAboveResources, loops*/ 17) day_changes.date = /*$datesAboveResources*/ ctx[4]
			? /*item0*/ ctx[18]
			: /*item1*/ ctx[21];

			if (dirty[0] & /*$datesAboveResources, loops*/ 17) day_changes.resource = /*$datesAboveResources*/ ctx[4]
			? /*item1*/ ctx[21]
			: /*item0*/ ctx[18];

			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(day, detaching);
		}
	};
}

// (61:1) {#each loops[0] as item0}
function create_each_block(ctx) {
	let div;
	let t;
	let div_class_value;
	let current;
	let each_value_1 = /*loops*/ ctx[0][1];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			attr(div, "class", div_class_value = /*$theme*/ ctx[5].resource);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			append(div, t);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$datesAboveResources, loops*/ 17) {
				each_value_1 = /*loops*/ ctx[0][1];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, t);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 32 && div_class_value !== (div_class_value = /*$theme*/ ctx[5].resource)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (60:0) <Body>
function create_default_slot(ctx) {
	let each_1_anchor;
	let current;
	let each_value = /*loops*/ ctx[0][0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, loops, $datesAboveResources*/ 49) {
				each_value = /*loops*/ ctx[0][0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

function create_fragment(ctx) {
	let header;
	let t0;
	let t1;
	let body;
	let current;

	header = new Header({
			props: {
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	let if_block = /*$allDaySlot*/ ctx[6] && create_if_block(ctx);

	body = new Body({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(header.$$.fragment);
			t0 = space();
			if (if_block) if_block.c();
			t1 = space();
			create_component(body.$$.fragment);
		},
		m(target, anchor) {
			mount_component(header, target, anchor);
			insert(target, t0, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, t1, anchor);
			mount_component(body, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const header_changes = {};

			if (dirty[0] & /*loops, $theme, titles*/ 35 | dirty[1] & /*$$scope*/ 32) {
				header_changes.$$scope = { dirty, ctx };
			}

			header.$set(header_changes);

			if (/*$allDaySlot*/ ctx[6]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*$allDaySlot*/ 64) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(t1.parentNode, t1);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			const body_changes = {};

			if (dirty[0] & /*loops, $theme, $datesAboveResources*/ 49 | dirty[1] & /*$$scope*/ 32) {
				body_changes.$$scope = { dirty, ctx };
			}

			body.$set(body_changes);
		},
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(if_block);
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(if_block);
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(header, detaching);
			if (detaching) detach(t0);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t1);
			destroy_component(body, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_intlDayHeader;
	let $_viewResources;
	let $_viewDates;
	let $datesAboveResources;
	let $_viewClass;
	let $theme;
	let $allDaySlot;
	let state = getContext('state');
	let { datesAboveResources, _viewDates, _intlDayHeader, _viewClass, allDaySlot, theme } = state;
	component_subscribe($$self, datesAboveResources, value => $$invalidate(4, $datesAboveResources = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(3, $_viewDates = value));
	component_subscribe($$self, _intlDayHeader, value => $$invalidate(14, $_intlDayHeader = value));
	component_subscribe($$self, _viewClass, value => $$invalidate(15, $_viewClass = value));
	component_subscribe($$self, allDaySlot, value => $$invalidate(6, $allDaySlot = value));
	component_subscribe($$self, theme, value => $$invalidate(5, $theme = value));
	let viewState = new State(state);
	setContext('view-state', viewState);
	let { _viewResources } = viewState;
	component_subscribe($$self, _viewResources, value => $$invalidate(2, $_viewResources = value));
	set_store_value(_viewClass, $_viewClass = 'week', $_viewClass);
	let loops, titles;

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$datesAboveResources, loops, $_viewDates, $_viewResources, titles, $_intlDayHeader*/ 16415) {
			{
				$$invalidate(0, loops = []);
				$$invalidate(1, titles = []);

				for (let i of $datesAboveResources ? [1, 0] : [0, 1]) {
					loops.push(i ? $_viewDates : $_viewResources);

					titles.push(i
					? date => $_intlDayHeader.format(date)
					: resource => resource.title);
				}
			}
		}
	};

	return [
		loops,
		titles,
		$_viewResources,
		$_viewDates,
		$datesAboveResources,
		$theme,
		$allDaySlot,
		datesAboveResources,
		_viewDates,
		_intlDayHeader,
		_viewClass,
		allDaySlot,
		theme,
		_viewResources,
		$_intlDayHeader
	];
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, null, [-1, -1]);
	}
}

var index = {
	createOptions(options) {
		options.resources = [];
		options.datesAboveResources = false;
		options.filterResourcesWithEvents = false;
		// Common options
		options.buttonText.resourceTimeGridDay = 'day';
		options.buttonText.resourceTimeGridWeek = 'week';
		options.theme.resource = 'ec-resource';
		options.theme.resourceTitle = 'ec-resource-title';
		options.view = 'resourceTimeGridWeek';
		options.views.resourceTimeGridDay = {
			component: View,
			duration: {days: 1}
		};
		options.views.resourceTimeGridWeek = {
			component: View,
			duration: {weeks: 1}
		};
	},
	createParsers(parsers, options) {
		parsers.resources = createResources;
	}
};

function createResources(input) {
	return input.map(resource => ({
		id: String(resource.id),
		title: resource.title || ''
	}));
}

export { index as default };
