import { SvelteComponent, init, safe_not_equal, element, space, attr, insert, append, noop, detach, destroy_each, component_subscribe, text, set_data, create_slot, listen, is_function, update_slot_base, get_all_dirty_from_scope, get_slot_changes, transition_in, transition_out, run_all, set_store_value, binding_callbacks, create_component, mount_component, action_destroyer, group_outros, destroy_component, check_outros, stop_propagation, bubble, empty } from 'svelte/internal';
import { getContext, tick, createEventDispatcher, onMount, afterUpdate, setContext } from 'svelte';
import { derived, writable } from 'svelte/store';
import { cloneDate, subtractDay, addDay, hasYScroll, sortEventChunks, setMidnight, datesEqual, setContent, toEventWithLocalDates, toViewWithLocalDates, createEventContent, height, ancestor, rect, createDate, toLocalDate, toISOString, assign, createEventChunk, intl } from '@event-calendar/common';

function days(state) {
    return derived([state.date, state.firstDay, state.hiddenDays], ([$date, $firstDay, $hiddenDays]) => {
        let days = [];
        let day = cloneDate($date);
        let max = 7;
        // First day of week
        while (day.getUTCDay() !== $firstDay && max) {
            subtractDay(day);
            --max;
        }
        for (let i = 0; i < 7; ++i) {
            if (!$hiddenDays.includes(day.getUTCDay())) {
                days.push(cloneDate(day));
            }
            addDay(day);
        }

        return days;
    });
}

class State {
    constructor(state) {
        this._days = days(state);
        this._hiddenEvents = writable({});
        this._popup = writable({
            date: null,
            chunks: []
        });
    }
}

/* packages/day-grid/src/Header.svelte generated by Svelte v3.49.0 */

function get_each_context$4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (10:2) {#each $_days as day}
function create_each_block$4(ctx) {
	let div;
	let t_value = /*$_intlDayHeader*/ ctx[3].format(/*day*/ ctx[8]) + "";
	let t;
	let div_class_value;

	return {
		c() {
			div = element("div");
			t = text(t_value);
			attr(div, "class", div_class_value = /*$theme*/ ctx[0].day);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*$_intlDayHeader, $_days*/ 12 && t_value !== (t_value = /*$_intlDayHeader*/ ctx[3].format(/*day*/ ctx[8]) + "")) set_data(t, t_value);

			if (dirty & /*$theme*/ 1 && div_class_value !== (div_class_value = /*$theme*/ ctx[0].day)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment$6(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let each_value = /*$_days*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$4(get_each_context$4(ctx, each_value, i));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			div1 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].days);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].hiddenScroll);

			attr(div2, "class", div2_class_value = "" + (/*$theme*/ ctx[0].header + (/*$_scrollable*/ ctx[1]
			? ' ' + /*$theme*/ ctx[0].withScroll
			: '')));
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}

			append(div2, t);
			append(div2, div1);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$theme, $_intlDayHeader, $_days*/ 13) {
				each_value = /*$_days*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$4(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block$4(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].days)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].hiddenScroll)) {
				attr(div1, "class", div1_class_value);
			}

			if (dirty & /*$theme, $_scrollable*/ 3 && div2_class_value !== (div2_class_value = "" + (/*$theme*/ ctx[0].header + (/*$_scrollable*/ ctx[1]
			? ' ' + /*$theme*/ ctx[0].withScroll
			: '')))) {
				attr(div2, "class", div2_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance$6($$self, $$props, $$invalidate) {
	let $theme;
	let $_scrollable;
	let $_days;
	let $_intlDayHeader;
	let { theme, _intlDayHeader, _scrollable } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));
	component_subscribe($$self, _intlDayHeader, value => $$invalidate(3, $_intlDayHeader = value));
	component_subscribe($$self, _scrollable, value => $$invalidate(1, $_scrollable = value));
	let { _days } = getContext('view-state');
	component_subscribe($$self, _days, value => $$invalidate(2, $_days = value));

	return [
		$theme,
		$_scrollable,
		$_days,
		$_intlDayHeader,
		theme,
		_intlDayHeader,
		_scrollable,
		_days
	];
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$6, create_fragment$6, safe_not_equal, {});
	}
}

/* packages/day-grid/src/Body.svelte generated by Svelte v3.49.0 */

function create_fragment$5(ctx) {
	let div1;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[12].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[11], null);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if (default_slot) default_slot.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[1].content);

			attr(div1, "class", div1_class_value = "" + (/*$theme*/ ctx[1].body + (/*$dayMaxEvents*/ ctx[2] === true
			? ' ' + /*$theme*/ ctx[1].uniform
			: '')));
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			if (default_slot) {
				default_slot.m(div0, null);
			}

			/*div1_binding*/ ctx[13](div1);
			current = true;

			if (!mounted) {
				dispose = [
					listen(window, "resize", /*recheckScrollable*/ ctx[9]),
					listen(div1, "scroll", function () {
						if (is_function(/*$_scroll*/ ctx[3])) /*$_scroll*/ ctx[3].apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 2048)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[11],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[11])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[11], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*$theme*/ 2 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[1].content)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme, $dayMaxEvents*/ 6 && div1_class_value !== (div1_class_value = "" + (/*$theme*/ ctx[1].body + (/*$dayMaxEvents*/ ctx[2] === true
			? ' ' + /*$theme*/ ctx[1].uniform
			: '')))) {
				attr(div1, "class", div1_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (default_slot) default_slot.d(detaching);
			/*div1_binding*/ ctx[13](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$5($$self, $$props, $$invalidate) {
	let $_scrollable;
	let $_events;
	let $theme;
	let $dayMaxEvents;
	let $_scroll;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { dayMaxEvents, _events, _scrollable, _scroll, theme } = getContext('state');
	component_subscribe($$self, dayMaxEvents, value => $$invalidate(2, $dayMaxEvents = value));
	component_subscribe($$self, _events, value => $$invalidate(10, $_events = value));
	component_subscribe($$self, _scrollable, value => $$invalidate(14, $_scrollable = value));
	component_subscribe($$self, _scroll, value => $$invalidate(3, $_scroll = value));
	component_subscribe($$self, theme, value => $$invalidate(1, $theme = value));
	let el;

	function recheckScrollable() {
		set_store_value(_scrollable, $_scrollable = hasYScroll(el), $_scrollable);
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(0, el);
		});
	}

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(11, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*el, $_events*/ 1025) {
			if (el && $_events) {
				tick().then(recheckScrollable);
			}
		}
	};

	return [
		el,
		$theme,
		$dayMaxEvents,
		$_scroll,
		dayMaxEvents,
		_events,
		_scrollable,
		_scroll,
		theme,
		recheckScrollable,
		$_events,
		$$scope,
		slots,
		div1_binding
	];
}

class Body extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$5, create_fragment$5, safe_not_equal, {});
	}
}

function prepareEventChunks(chunks, hiddenDays) {
    if (!chunks.length) {
        return;
    }

    sortEventChunks(chunks);

    let longChunks = {};
    let prevChunk;
    for (let chunk of chunks) {
        let dates = [];
        let date = setMidnight(cloneDate(chunk.start));
        while (chunk.end > date) {
            if (!hiddenDays.includes(date.getUTCDay())) {
                dates.push(cloneDate(date));
                if (dates.length > 1) {
                    let key = date.getTime();
                    if (longChunks[key]) {
                        longChunks[key].push(chunk);
                    } else {
                        longChunks[key] = [chunk];
                    }
                }
            }
            addDay(date);
        }
        if (dates.length) {
            chunk.date = dates[0];
            chunk.days = dates.length;
            chunk.dates = dates;
            if (chunk.start < dates[0]) {
                chunk.start = dates[0];
            }
            if (setMidnight(cloneDate(chunk.end)) > dates[dates.length - 1]) {
                chunk.end = dates[dates.length - 1];
            }
        } else {
            chunk.date = setMidnight(cloneDate(chunk.start));
            chunk.days = 1;
            chunk.dates = [chunk.date];
        }

        if (prevChunk && datesEqual(prevChunk.date, chunk.date)) {
            chunk.prev = prevChunk;
        }
        prevChunk = chunk;
    }

    return longChunks;
}

/* packages/day-grid/src/Event.svelte generated by Svelte v3.49.0 */

function create_fragment$4(ctx) {
	let div1;
	let div0;
	let div0_class_value;
	let setContent_action;
	let t;
	let switch_instance;
	let current;
	let mounted;
	let dispose;
	var switch_value = /*$_interaction*/ ctx[7].resizer;

	function switch_props(ctx) {
		return { props: { event: /*event*/ ctx[0] } };
	}

	if (switch_value) {
		switch_instance = new switch_value(switch_props(ctx));
		switch_instance.$on("pointerdown", /*createDragHandler*/ ctx[30](true));
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			t = space();
			if (switch_instance) create_component(switch_instance.$$.fragment);
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[1].eventBody);
			attr(div1, "class", /*classes*/ ctx[3]);
			attr(div1, "style", /*style*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div1, t);

			if (switch_instance) {
				mount_component(switch_instance, div1, null);
			}

			/*div1_binding*/ ctx[45](div1);
			current = true;

			if (!mounted) {
				dispose = [
					listen(window, "resize", /*reposition*/ ctx[31]),
					action_destroyer(setContent_action = setContent.call(null, div0, /*content*/ ctx[5])),
					listen(div1, "click", function () {
						if (is_function(/*createClickHandler*/ ctx[29](/*$eventClick*/ ctx[8], /*display*/ ctx[6]))) /*createClickHandler*/ ctx[29](/*$eventClick*/ ctx[8], /*display*/ ctx[6]).apply(this, arguments);
					}),
					listen(div1, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[28](/*$eventMouseEnter*/ ctx[9], /*display*/ ctx[6]))) /*createHandler*/ ctx[28](/*$eventMouseEnter*/ ctx[9], /*display*/ ctx[6]).apply(this, arguments);
					}),
					listen(div1, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[28](/*$eventMouseLeave*/ ctx[10], /*display*/ ctx[6]))) /*createHandler*/ ctx[28](/*$eventMouseLeave*/ ctx[10], /*display*/ ctx[6]).apply(this, arguments);
					}),
					listen(div1, "pointerdown", function () {
						if (is_function(/*display*/ ctx[6] === 'auto' && /*$_draggable*/ ctx[11](/*event*/ ctx[0])
						? /*createDragHandler*/ ctx[30]()
						: undefined)) (/*display*/ ctx[6] === 'auto' && /*$_draggable*/ ctx[11](/*event*/ ctx[0])
						? /*createDragHandler*/ ctx[30]()
						: undefined).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!current || dirty[0] & /*$theme*/ 2 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[1].eventBody)) {
				attr(div0, "class", div0_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 32) setContent_action.update.call(null, /*content*/ ctx[5]);
			const switch_instance_changes = {};
			if (dirty[0] & /*event*/ 1) switch_instance_changes.event = /*event*/ ctx[0];

			if (switch_value !== (switch_value = /*$_interaction*/ ctx[7].resizer)) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = new switch_value(switch_props(ctx));
					switch_instance.$on("pointerdown", /*createDragHandler*/ ctx[30](true));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, div1, null);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				switch_instance.$set(switch_instance_changes);
			}

			if (!current || dirty[0] & /*classes*/ 8) {
				attr(div1, "class", /*classes*/ ctx[3]);
			}

			if (!current || dirty[0] & /*style*/ 16) {
				attr(div1, "style", /*style*/ ctx[4]);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (switch_instance) destroy_component(switch_instance);
			/*div1_binding*/ ctx[45](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$4($$self, $$props, $$invalidate) {
	let $_hiddenEvents;
	let $dayMaxEvents;
	let $_interaction;
	let $_view;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $_classes;
	let $eventColor;
	let $eventBackgroundColor;
	let $eventClick;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let $_draggable;
	let { chunk } = $$props;
	let { longChunks = {} } = $$props;
	let { inPopup = false } = $$props;
	let { dayMaxEvents, displayEventEnd, eventBackgroundColor, eventClick, eventColor, eventContent, eventDidMount, eventMouseEnter, eventMouseLeave, theme, _view, _intlEventTime, _interaction, _classes, _draggable } = getContext('state');
	component_subscribe($$self, dayMaxEvents, value => $$invalidate(47, $dayMaxEvents = value));
	component_subscribe($$self, displayEventEnd, value => $$invalidate(41, $displayEventEnd = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(44, $eventBackgroundColor = value));
	component_subscribe($$self, eventClick, value => $$invalidate(8, $eventClick = value));
	component_subscribe($$self, eventColor, value => $$invalidate(43, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(40, $eventContent = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(48, $eventDidMount = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(9, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(10, $eventMouseLeave = value));
	component_subscribe($$self, theme, value => $$invalidate(1, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(38, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(39, $_intlEventTime = value));
	component_subscribe($$self, _interaction, value => $$invalidate(7, $_interaction = value));
	component_subscribe($$self, _classes, value => $$invalidate(42, $_classes = value));
	component_subscribe($$self, _draggable, value => $$invalidate(11, $_draggable = value));
	let { _hiddenEvents } = getContext('view-state');
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(37, $_hiddenEvents = value));
	createEventDispatcher();
	let el;
	let event;
	let classes;
	let style;
	let content;
	let timeText;
	let margin = 1;
	let hidden = false;
	let display;

	onMount(() => {
		if (is_function($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	afterUpdate(reposition);

	function createHandler(fn, display) {
		return display !== 'preview' && is_function(fn)
		? jsEvent => fn({
				event: toEventWithLocalDates(event),
				el,
				jsEvent,
				view: toViewWithLocalDates($_view)
			})
		: undefined;
	}

	function createClickHandler(fn, display) {
		let handler = createHandler(fn, display);

		return handler
		? jsEvent => !jsEvent.ecClosingPopup && handler(jsEvent)
		: handler;
	}

	function createDragHandler(resize) {
		return jsEvent => $_interaction.drag.startDayGrid(event, el, jsEvent, inPopup, resize);
	}

	function reposition() {
		if (!el || display === 'preview' || inPopup) {
			return;
		}

		$$invalidate(32, chunk.top = 0, chunk);

		if (chunk.prev) {
			if (chunk.prev.bottom === undefined) {
				// 'prev' is not ready yet, try again later
				tick().then(reposition);

				return;
			}

			$$invalidate(32, chunk.top = chunk.prev.bottom + 1, chunk);
		}

		$$invalidate(32, chunk.bottom = chunk.top + height(el), chunk);
		let m = 1;
		let key = chunk.date.getTime();

		if (longChunks[key]) {
			for (let longChunk of longChunks[key]) {
				if (longChunk.bottom === undefined) {
					// 'longChunk' is not ready yet, try again later
					tick().then(reposition);

					return;
				}

				if (chunk.top < longChunk.bottom && chunk.bottom > longChunk.top) {
					let offset = longChunk.bottom - chunk.top + 1;
					m += offset;
					$$invalidate(32, chunk.top += offset, chunk);
					$$invalidate(32, chunk.bottom += offset, chunk);
				}
			}
		}

		$$invalidate(35, margin = m);

		if ($dayMaxEvents === true) {
			hide();
		} else {
			$$invalidate(36, hidden = false);
		}
	}

	function hide() {
		if (!el) {
			return;
		}

		let dayEl = ancestor(el, 2);
		let h = height(dayEl) - height(dayEl.firstElementChild) - footHeight(dayEl);
		$$invalidate(36, hidden = chunk.bottom > h);
		cloneDate(chunk.date);
		let update = false;

		// Hide or show the event throughout all days
		for (let date of chunk.dates) {
			let hiddenEvents = $_hiddenEvents[date.getTime()];

			if (hiddenEvents) {
				let size = hiddenEvents.size;

				if (hidden) {
					hiddenEvents.add(chunk.event);
				} else {
					hiddenEvents.delete(chunk.event);
				}

				if (size !== hiddenEvents.size) {
					update = true;
				}
			}
		}

		if (update) {
			_hiddenEvents.set($_hiddenEvents);
		}
	}

	function footHeight(dayEl) {
		let h = 0;

		for (let i = 0; i < chunk.days; ++i) {
			h = Math.max(h, height(dayEl.lastElementChild));
			dayEl = dayEl.nextElementSibling;

			if (!dayEl) {
				break;
			}
		}

		return h;
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(2, el);
		});
	}

	$$self.$$set = $$props => {
		if ('chunk' in $$props) $$invalidate(32, chunk = $$props.chunk);
		if ('longChunks' in $$props) $$invalidate(33, longChunks = $$props.longChunks);
		if ('inPopup' in $$props) $$invalidate(34, inPopup = $$props.inPopup);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[1] & /*chunk*/ 2) {
			$$invalidate(0, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, style, $theme*/ 19 | $$self.$$.dirty[1] & /*$eventBackgroundColor, $eventColor, chunk, margin, hidden, $_classes*/ 14386) {
			{
				$$invalidate(6, display = event.display);

				// Class & Style
				let bgColor = event.backgroundColor || $eventBackgroundColor || $eventColor;

				$$invalidate(4, style = `width:calc(${chunk.days * 100}% + ${(chunk.days - 1) * 7}px);` + `margin-top:${margin}px;`);

				if (bgColor) {
					$$invalidate(4, style += `background-color:${bgColor};`);
				}

				if (hidden) {
					$$invalidate(4, style += 'visibility:hidden;');
				}

				$$invalidate(3, classes = $_classes($theme.event, event));
			}
		}

		if ($$self.$$.dirty[0] & /*$theme*/ 2 | $$self.$$.dirty[1] & /*chunk, $displayEventEnd, $eventContent, $_intlEventTime, $_view*/ 1922) {
			// Content
			$$invalidate(5, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
		}

		if ($$self.$$.dirty[1] & /*$_hiddenEvents*/ 64) {
			if ($_hiddenEvents) {
				tick().then(reposition);
			}
		}
	};

	return [
		event,
		$theme,
		el,
		classes,
		style,
		content,
		display,
		$_interaction,
		$eventClick,
		$eventMouseEnter,
		$eventMouseLeave,
		$_draggable,
		dayMaxEvents,
		displayEventEnd,
		eventBackgroundColor,
		eventClick,
		eventColor,
		eventContent,
		eventDidMount,
		eventMouseEnter,
		eventMouseLeave,
		theme,
		_view,
		_intlEventTime,
		_interaction,
		_classes,
		_draggable,
		_hiddenEvents,
		createHandler,
		createClickHandler,
		createDragHandler,
		reposition,
		chunk,
		longChunks,
		inPopup,
		margin,
		hidden,
		$_hiddenEvents,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$_classes,
		$eventColor,
		$eventBackgroundColor,
		div1_binding
	];
}

class Event extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$4, create_fragment$4, safe_not_equal, { chunk: 32, longChunks: 33, inPopup: 34 }, null, [-1, -1]);
	}
}

/** Dispatch event on click outside of node */
function clickOutside(node) {

    const handleClick = clickEvent => {
        if (node && !node.contains(clickEvent.target)) {
            node.dispatchEvent(
                new CustomEvent('clickoutside', {detail: {clickEvent}})
            );
        }
    };

    document.addEventListener('click', handleClick, true);

    return {
        destroy() {
            document.removeEventListener('click', handleClick, true);
        }
    };
}

/* packages/day-grid/src/Popup.svelte generated by Svelte v3.49.0 */

function get_each_context$3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[12] = list[i];
	return child_ctx;
}

// (50:8) {#each $_popup.chunks as chunk}
function create_each_block$3(ctx) {
	let event;
	let current;

	event = new Event({
			props: { chunk: /*chunk*/ ctx[12], inPopup: true }
		});

	return {
		c() {
			create_component(event.$$.fragment);
		},
		m(target, anchor) {
			mount_component(event, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty & /*$_popup*/ 4) event_changes.chunk = /*chunk*/ ctx[12];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(event, detaching);
		}
	};
}

function create_fragment$3(ctx) {
	let div2;
	let div0;
	let t0_value = /*$_intlDayPopover*/ ctx[4].format(/*$_popup*/ ctx[2].date) + "";
	let t0;
	let t1;
	let a;
	let div0_class_value;
	let t3;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value = /*$_popup*/ ctx[2].chunks;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$3(get_each_context$3(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			a = element("a");
			a.textContent = "×";
			t3 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", div0_class_value = /*$theme*/ ctx[3].dayHead);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[3].events);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[3].popup);
			attr(div2, "style", /*style*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, t0);
			append(div0, t1);
			append(div0, a);
			append(div2, t3);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			/*div2_binding*/ ctx[11](div2);
			current = true;

			if (!mounted) {
				dispose = [
					listen(a, "click", stop_propagation(/*close*/ ctx[8])),
					listen(div2, "click", stop_propagation(/*click_handler*/ ctx[10])),
					action_destroyer(clickOutside.call(null, div2)),
					listen(div2, "clickoutside", /*handleClickOutside*/ ctx[9])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*$_intlDayPopover, $_popup*/ 20) && t0_value !== (t0_value = /*$_intlDayPopover*/ ctx[4].format(/*$_popup*/ ctx[2].date) + "")) set_data(t0, t0_value);

			if (!current || dirty & /*$theme*/ 8 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[3].dayHead)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$_popup*/ 4) {
				each_value = /*$_popup*/ ctx[2].chunks;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$3(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$3(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 8 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[3].events)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty & /*$theme*/ 8 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[3].popup)) {
				attr(div2, "class", div2_class_value);
			}

			if (!current || dirty & /*style*/ 2) {
				attr(div2, "style", /*style*/ ctx[1]);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			destroy_each(each_blocks, detaching);
			/*div2_binding*/ ctx[11](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let $_popup;
	let $theme;
	let $_intlDayPopover;
	let { theme, _intlDayPopover } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(3, $theme = value));
	component_subscribe($$self, _intlDayPopover, value => $$invalidate(4, $_intlDayPopover = value));
	let { _popup } = getContext('view-state');
	component_subscribe($$self, _popup, value => $$invalidate(2, $_popup = value));
	let el;
	let style = '';

	onMount(() => {
		let dayEl = ancestor(el, 1);
		let bodyEl = ancestor(dayEl, 3);
		let popupRect = rect(el);
		let bodyRect = rect(bodyEl);

		if (!dayEl.previousElementSibling) {
			$$invalidate(1, style = 'left:0;');
		} else if (!dayEl.nextElementSibling) {
			$$invalidate(1, style = 'right:0;');
		} else {
			let left = (dayEl.offsetWidth - popupRect.width) / 2;
			$$invalidate(1, style = `left:${left}px;`);
		}

		let top = (dayEl.offsetHeight - popupRect.height) / 2;

		if (popupRect.top + top < bodyRect.top) {
			top = bodyRect.top - popupRect.top;
		} else if (popupRect.bottom + top > bodyRect.bottom) {
			top = bodyRect.bottom - popupRect.bottom;
		}

		$$invalidate(1, style += `top:${top}px;`);
	});

	function close(e) {
		set_store_value(_popup, $_popup.date = null, $_popup);
	}

	function handleClickOutside(e) {
		close();
		e.detail.clickEvent.ecClosingPopup = true;
	}

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(0, el);
		});
	}

	return [
		el,
		style,
		$_popup,
		$theme,
		$_intlDayPopover,
		theme,
		_intlDayPopover,
		_popup,
		close,
		handleClickOutside,
		click_handler,
		div2_binding
	];
}

class Popup extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$3, create_fragment$3, safe_not_equal, {});
	}
}

/* packages/day-grid/src/Day.svelte generated by Svelte v3.49.0 */

function get_each_context$2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[36] = list[i];
	return child_ctx;
}

// (111:1) {#if iChunks[1] && datesEqual(iChunks[1].date, date)}
function create_if_block_3(ctx) {
	let div;
	let event;
	let div_class_value;
	let current;
	event = new Event({ props: { chunk: /*iChunks*/ ctx[2][1] } });

	return {
		c() {
			div = element("div");
			create_component(event.$$.fragment);
			attr(div, "class", div_class_value = /*$theme*/ ctx[11].events);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(event, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*iChunks*/ 4) event_changes.chunk = /*iChunks*/ ctx[2][1];
			event.$set(event_changes);

			if (!current || dirty[0] & /*$theme*/ 2048 && div_class_value !== (div_class_value = /*$theme*/ ctx[11].events)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(event);
		}
	};
}

// (117:1) {#if iChunks[0] && datesEqual(iChunks[0].date, date)}
function create_if_block_2(ctx) {
	let div;
	let event;
	let div_class_value;
	let current;
	event = new Event({ props: { chunk: /*iChunks*/ ctx[2][0] } });

	return {
		c() {
			div = element("div");
			create_component(event.$$.fragment);
			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[11].events + " " + /*$theme*/ ctx[11].preview));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(event, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*iChunks*/ 4) event_changes.chunk = /*iChunks*/ ctx[2][0];
			event.$set(event_changes);

			if (!current || dirty[0] & /*$theme*/ 2048 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[11].events + " " + /*$theme*/ ctx[11].preview))) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(event);
		}
	};
}

// (123:2) {#each dayChunks as chunk}
function create_each_block$2(ctx) {
	let event;
	let current;

	event = new Event({
			props: {
				chunk: /*chunk*/ ctx[36],
				longChunks: /*longChunks*/ ctx[1]
			}
		});

	return {
		c() {
			create_component(event.$$.fragment);
		},
		m(target, anchor) {
			mount_component(event, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*dayChunks*/ 8) event_changes.chunk = /*chunk*/ ctx[36];
			if (dirty[0] & /*longChunks*/ 2) event_changes.longChunks = /*longChunks*/ ctx[1];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(event, detaching);
		}
	};
}

// (127:1) {#if $_popup.date && datesEqual(date, $_popup.date)}
function create_if_block_1(ctx) {
	let popup;
	let current;
	popup = new Popup({});

	return {
		c() {
			create_component(popup.$$.fragment);
		},
		m(target, anchor) {
			mount_component(popup, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(popup.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(popup.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(popup, detaching);
		}
	};
}

// (131:2) {#if hiddenEvents.size}
function create_if_block(ctx) {
	let a;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			a = element("a");
		},
		m(target, anchor) {
			insert(target, a, anchor);

			if (!mounted) {
				dispose = [
					listen(a, "click", stop_propagation(/*showMore*/ ctx[25])),
					action_destroyer(setContent_action = setContent.call(null, a, /*moreLink*/ ctx[5]))
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*moreLink*/ 32) setContent_action.update.call(null, /*moreLink*/ ctx[5]);
		},
		d(detaching) {
			if (detaching) detach(a);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment$2(ctx) {
	let div3;
	let div0;
	let t0_value = /*date*/ ctx[0].getUTCDate() + "";
	let t0;
	let div0_class_value;
	let t1;
	let show_if_2 = /*iChunks*/ ctx[2][1] && datesEqual(/*iChunks*/ ctx[2][1].date, /*date*/ ctx[0]);
	let t2;
	let show_if_1 = /*iChunks*/ ctx[2][0] && datesEqual(/*iChunks*/ ctx[2][0].date, /*date*/ ctx[0]);
	let t3;
	let div1;
	let div1_class_value;
	let t4;
	let show_if = /*$_popup*/ ctx[6].date && datesEqual(/*date*/ ctx[0], /*$_popup*/ ctx[6].date);
	let t5;
	let div2;
	let div2_class_value;
	let div3_class_value;
	let current;
	let mounted;
	let dispose;
	let if_block0 = show_if_2 && create_if_block_3(ctx);
	let if_block1 = show_if_1 && create_if_block_2(ctx);
	let each_value = /*dayChunks*/ ctx[3];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$2(get_each_context$2(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	let if_block2 = show_if && create_if_block_1();
	let if_block3 = /*hiddenEvents*/ ctx[4].size && create_if_block(ctx);

	return {
		c() {
			div3 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			t3 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t4 = space();
			if (if_block2) if_block2.c();
			t5 = space();
			div2 = element("div");
			if (if_block3) if_block3.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[11].dayHead);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[11].events);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[11].dayFoot);

			attr(div3, "class", div3_class_value = "" + (/*$theme*/ ctx[11].day + (/*isToday*/ ctx[8] ? ' ' + /*$theme*/ ctx[11].today : '') + (/*otherMonth*/ ctx[9]
			? ' ' + /*$theme*/ ctx[11].otherMonth
			: '') + (/*highlight*/ ctx[10]
			? ' ' + /*$theme*/ ctx[11].highlight
			: '')));
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div0);
			append(div0, t0);
			append(div3, t1);
			if (if_block0) if_block0.m(div3, null);
			append(div3, t2);
			if (if_block1) if_block1.m(div3, null);
			append(div3, t3);
			append(div3, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			append(div3, t4);
			if (if_block2) if_block2.m(div3, null);
			append(div3, t5);
			append(div3, div2);
			if (if_block3) if_block3.m(div2, null);
			/*div3_binding*/ ctx[31](div3);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div3, "click", function () {
						if (is_function(/*createClickHandler*/ ctx[23](/*$dateClick*/ ctx[12]))) /*createClickHandler*/ ctx[23](/*$dateClick*/ ctx[12]).apply(this, arguments);
					}),
					listen(div3, "pointerenter", function () {
						if (is_function(/*createPointerEnterHandler*/ ctx[24](/*$_interaction*/ ctx[13]))) /*createPointerEnterHandler*/ ctx[24](/*$_interaction*/ ctx[13]).apply(this, arguments);
					}),
					listen(div3, "pointerleave", function () {
						if (is_function(createPointerLeaveHandler(/*$_interaction*/ ctx[13]))) createPointerLeaveHandler(/*$_interaction*/ ctx[13]).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if ((!current || dirty[0] & /*date*/ 1) && t0_value !== (t0_value = /*date*/ ctx[0].getUTCDate() + "")) set_data(t0, t0_value);

			if (!current || dirty[0] & /*$theme*/ 2048 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[11].dayHead)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty[0] & /*iChunks, date*/ 5) show_if_2 = /*iChunks*/ ctx[2][1] && datesEqual(/*iChunks*/ ctx[2][1].date, /*date*/ ctx[0]);

			if (show_if_2) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*iChunks, date*/ 5) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div3, t2);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*iChunks, date*/ 5) show_if_1 = /*iChunks*/ ctx[2][0] && datesEqual(/*iChunks*/ ctx[2][0].date, /*date*/ ctx[0]);

			if (show_if_1) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*iChunks, date*/ 5) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div3, t3);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*dayChunks, longChunks*/ 10) {
				each_value = /*dayChunks*/ ctx[3];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$2(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$2(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 2048 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[11].events)) {
				attr(div1, "class", div1_class_value);
			}

			if (dirty[0] & /*$_popup, date*/ 65) show_if = /*$_popup*/ ctx[6].date && datesEqual(/*date*/ ctx[0], /*$_popup*/ ctx[6].date);

			if (show_if) {
				if (if_block2) {
					if (dirty[0] & /*$_popup, date*/ 65) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_1();
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div3, t5);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (/*hiddenEvents*/ ctx[4].size) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block(ctx);
					if_block3.c();
					if_block3.m(div2, null);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (!current || dirty[0] & /*$theme*/ 2048 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[11].dayFoot)) {
				attr(div2, "class", div2_class_value);
			}

			if (!current || dirty[0] & /*$theme, isToday, otherMonth, highlight*/ 3840 && div3_class_value !== (div3_class_value = "" + (/*$theme*/ ctx[11].day + (/*isToday*/ ctx[8] ? ' ' + /*$theme*/ ctx[11].today : '') + (/*otherMonth*/ ctx[9]
			? ' ' + /*$theme*/ ctx[11].otherMonth
			: '') + (/*highlight*/ ctx[10]
			? ' ' + /*$theme*/ ctx[11].highlight
			: '')))) {
				attr(div3, "class", div3_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			destroy_each(each_blocks, detaching);
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			/*div3_binding*/ ctx[31](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function createPointerLeaveHandler(interaction) {
	return interaction.pointer
	? interaction.pointer.leave
	: undefined;
}

function instance$2($$self, $$props, $$invalidate) {
	let $_popup;
	let $_view;
	let $moreLinkContent;
	let $_hiddenEvents;
	let $highlightedDates;
	let $currentDate;
	let $theme;
	let $dateClick;
	let $_interaction;
	let { date } = $$props;
	let { chunks } = $$props;
	let { longChunks } = $$props;
	let { iChunks = [] } = $$props;
	let { date: currentDate, dateClick, dayMaxEvents, highlightedDates, moreLinkContent, theme, _view, _interaction } = getContext('state');
	component_subscribe($$self, currentDate, value => $$invalidate(30, $currentDate = value));
	component_subscribe($$self, dateClick, value => $$invalidate(12, $dateClick = value));
	component_subscribe($$self, highlightedDates, value => $$invalidate(29, $highlightedDates = value));
	component_subscribe($$self, moreLinkContent, value => $$invalidate(27, $moreLinkContent = value));
	component_subscribe($$self, theme, value => $$invalidate(11, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(32, $_view = value));
	component_subscribe($$self, _interaction, value => $$invalidate(13, $_interaction = value));
	let { _hiddenEvents, _popup } = getContext('view-state');
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(28, $_hiddenEvents = value));
	component_subscribe($$self, _popup, value => $$invalidate(6, $_popup = value));
	let el;
	let dayChunks;
	let today = setMidnight(createDate());
	let isToday;
	let otherMonth;
	let highlight;
	let hiddenEvents = new Set(); // hidden events of this day
	let moreLink = '';

	function createClickHandler(fn) {
		return is_function(fn)
		? jsEvent => {
				!jsEvent.ecClosingPopup && fn({
					date: toLocalDate(date),
					dateStr: toISOString(date),
					dayEl: el,
					jsEvent,
					view: toViewWithLocalDates($_view)
				});
			}
		: undefined;
	}

	function createPointerEnterHandler(interaction) {
		return interaction.pointer
		? jsEvent => interaction.pointer.enterDayGrid(date, jsEvent)
		: undefined;
	}

	function showMore() {
		setPopupChunks();
		set_store_value(_popup, $_popup.date = date, $_popup);
	}

	function setPopupChunks() {
		let nextDay = addDay(cloneDate(date));
		set_store_value(_popup, $_popup.chunks = dayChunks.concat(longChunks[date.getTime()] || []).map(c => assign({}, c, createEventChunk(c.event, date, nextDay), { days: 1, dates: [date] })).sort((a, b) => a.top - b.top), $_popup);
	}

	function div3_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(7, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
		if ('chunks' in $$props) $$invalidate(26, chunks = $$props.chunks);
		if ('longChunks' in $$props) $$invalidate(1, longChunks = $$props.longChunks);
		if ('iChunks' in $$props) $$invalidate(2, iChunks = $$props.iChunks);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*hiddenEvents, chunks, date, dayChunks*/ 67108889) {
			{
				$$invalidate(3, dayChunks = []);
				hiddenEvents.clear();
				((($$invalidate(4, hiddenEvents), $$invalidate(26, chunks)), $$invalidate(0, date)), $$invalidate(3, dayChunks));

				for (let chunk of chunks) {
					if (datesEqual(chunk.date, date)) {
						dayChunks.push(chunk);
					} // if ($dayMaxEvents !== false && dayChunks.length > $dayMaxEvents) {
					// 	chunk.hidden = true;
				} // }
			}
		}

		if ($$self.$$.dirty[0] & /*date, hiddenEvents*/ 17) {
			set_store_value(_hiddenEvents, $_hiddenEvents[date.getTime()] = hiddenEvents, $_hiddenEvents);
		}

		if ($$self.$$.dirty[0] & /*date, $currentDate, $highlightedDates*/ 1610612737) {
			{
				$$invalidate(8, isToday = datesEqual(date, today));
				$$invalidate(9, otherMonth = date.getUTCMonth() !== $currentDate.getUTCMonth());
				$$invalidate(10, highlight = $highlightedDates.some(d => datesEqual(d, date)));
			}
		}

		if ($$self.$$.dirty[0] & /*$_hiddenEvents, hiddenEvents, $moreLinkContent, moreLink*/ 402653232) {
			if ($_hiddenEvents && hiddenEvents.size) {
				// make Svelte update this block on $_hiddenEvents update
				let text = '+' + hiddenEvents.size + ' more';

				if ($moreLinkContent) {
					$$invalidate(5, moreLink = is_function($moreLinkContent)
					? $moreLinkContent({ num: hiddenEvents.size, text })
					: $moreLinkContent);

					if (typeof moreLink === 'string') {
						$$invalidate(5, moreLink = { html: moreLink });
					}
				} else {
					$$invalidate(5, moreLink = { html: text });
				}
			}
		}

		if ($$self.$$.dirty[0] & /*$_popup, date, longChunks, dayChunks*/ 75) {
			if ($_popup.date && datesEqual(date, $_popup.date) && longChunks && dayChunks) {
				setPopupChunks();
			}
		}
	};

	return [
		date,
		longChunks,
		iChunks,
		dayChunks,
		hiddenEvents,
		moreLink,
		$_popup,
		el,
		isToday,
		otherMonth,
		highlight,
		$theme,
		$dateClick,
		$_interaction,
		currentDate,
		dateClick,
		highlightedDates,
		moreLinkContent,
		theme,
		_view,
		_interaction,
		_hiddenEvents,
		_popup,
		createClickHandler,
		createPointerEnterHandler,
		showMore,
		chunks,
		$moreLinkContent,
		$_hiddenEvents,
		$highlightedDates,
		$currentDate,
		div3_binding
	];
}

class Day extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$2,
			create_fragment$2,
			safe_not_equal,
			{
				date: 0,
				chunks: 26,
				longChunks: 1,
				iChunks: 2
			},
			null,
			[-1, -1]
		);
	}
}

/* packages/day-grid/src/Week.svelte generated by Svelte v3.49.0 */

function get_each_context$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[15] = list[i];
	return child_ctx;
}

// (50:1) {#each dates as date}
function create_each_block$1(ctx) {
	let day;
	let current;

	day = new Day({
			props: {
				date: /*date*/ ctx[15],
				chunks: /*chunks*/ ctx[1],
				longChunks: /*longChunks*/ ctx[2],
				iChunks: /*iChunks*/ ctx[3]
			}
		});

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const day_changes = {};
			if (dirty & /*dates*/ 1) day_changes.date = /*date*/ ctx[15];
			if (dirty & /*chunks*/ 2) day_changes.chunks = /*chunks*/ ctx[1];
			if (dirty & /*longChunks*/ 4) day_changes.longChunks = /*longChunks*/ ctx[2];
			if (dirty & /*iChunks*/ 8) day_changes.iChunks = /*iChunks*/ ctx[3];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(day, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let div;
	let div_class_value;
	let current;
	let each_value = /*dates*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$1(get_each_context$1(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", div_class_value = /*$theme*/ ctx[4].days);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*dates, chunks, longChunks, iChunks*/ 15) {
				each_value = /*dates*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$1(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 16 && div_class_value !== (div_class_value = /*$theme*/ ctx[4].days)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $hiddenDays;
	let $_iEvents;
	let $_events;
	let $theme;
	let { dates } = $$props;
	let { _events, _iEvents, hiddenDays, theme } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(13, $_events = value));
	component_subscribe($$self, _iEvents, value => $$invalidate(12, $_iEvents = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(11, $hiddenDays = value));
	component_subscribe($$self, theme, value => $$invalidate(4, $theme = value));
	let chunks, longChunks, iChunks = [];
	let start;
	let end;

	function intersects(event) {
		return event.start < end && event.end > start || datesEqual(event.start, event.end, start);
	}

	$$self.$$set = $$props => {
		if ('dates' in $$props) $$invalidate(0, dates = $$props.dates);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*dates*/ 1) {
			{
				$$invalidate(9, start = dates[0]);
				$$invalidate(10, end = addDay(cloneDate(dates[dates.length - 1])));
			}
		}

		if ($$self.$$.dirty & /*$_events, start, end, chunks, $hiddenDays*/ 11778) {
			{
				$$invalidate(1, chunks = []);

				for (let event of $_events) {
					if (event.display !== 'background' && intersects(event)) {
						let chunk = createEventChunk(event, start, end);
						chunks.push(chunk);
					}
				}

				$$invalidate(2, longChunks = prepareEventChunks(chunks, $hiddenDays));
			}
		}

		if ($$self.$$.dirty & /*$_iEvents, start, end, $hiddenDays*/ 7680) {
			$$invalidate(3, iChunks = $_iEvents.map(event => {
				let chunk;

				if (event && intersects(event)) {
					chunk = createEventChunk(event, start, end);
					prepareEventChunks([chunk], $hiddenDays);
				} else {
					chunk = null;
				}

				return chunk;
			}));
		}
	};

	return [
		dates,
		chunks,
		longChunks,
		iChunks,
		$theme,
		_events,
		_iEvents,
		hiddenDays,
		theme,
		start,
		end,
		$hiddenDays,
		$_iEvents,
		$_events
	];
}

class Week extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$1, safe_not_equal, { dates: 0 });
	}
}

/* packages/day-grid/src/View.svelte generated by Svelte v3.49.0 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[14] = list[i];
	return child_ctx;
}

// (37:1) {#each weeks as dates}
function create_each_block(ctx) {
	let week;
	let current;
	week = new Week({ props: { dates: /*dates*/ ctx[14] } });

	return {
		c() {
			create_component(week.$$.fragment);
		},
		m(target, anchor) {
			mount_component(week, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const week_changes = {};
			if (dirty & /*weeks*/ 1) week_changes.dates = /*dates*/ ctx[14];
			week.$set(week_changes);
		},
		i(local) {
			if (current) return;
			transition_in(week.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(week.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(week, detaching);
		}
	};
}

// (36:0) <Body>
function create_default_slot(ctx) {
	let each_1_anchor;
	let current;
	let each_value = /*weeks*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*weeks*/ 1) {
				each_value = /*weeks*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

function create_fragment(ctx) {
	let header;
	let t;
	let body;
	let current;
	header = new Header({});

	body = new Body({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(header.$$.fragment);
			t = space();
			create_component(body.$$.fragment);
		},
		m(target, anchor) {
			mount_component(header, target, anchor);
			insert(target, t, anchor);
			mount_component(body, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const body_changes = {};

			if (dirty & /*$$scope, weeks*/ 131073) {
				body_changes.$$scope = { dirty, ctx };
			}

			body.$set(body_changes);
		},
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(header, detaching);
			if (detaching) detach(t);
			destroy_component(body, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_viewDates;
	let $dayMaxEvents;
	let $_hiddenEvents;
	let $hiddenDays;
	let $_viewClass;
	let { _viewDates, _viewClass, dayMaxEvents, hiddenDays, theme } = getContext('state');
	component_subscribe($$self, _viewDates, value => $$invalidate(7, $_viewDates = value));
	component_subscribe($$self, _viewClass, value => $$invalidate(11, $_viewClass = value));
	component_subscribe($$self, dayMaxEvents, value => $$invalidate(8, $dayMaxEvents = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(9, $hiddenDays = value));
	let viewState = new State(getContext('state'));
	setContext('view-state', viewState);
	let { _hiddenEvents } = viewState;
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(10, $_hiddenEvents = value));
	set_store_value(_viewClass, $_viewClass = 'month', $_viewClass);
	let weeks;
	let days;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$hiddenDays, $dayMaxEvents, $_viewDates, days, weeks*/ 961) {
			{
				$$invalidate(0, weeks = []);
				$$invalidate(6, days = 7 - $hiddenDays.length);
				set_store_value(_hiddenEvents, $_hiddenEvents = {}, $_hiddenEvents);

				for (let i = 0; i < $_viewDates.length / days; ++i) {
					let dates = [];

					for (let j = 0; j < days; ++j) {
						dates.push($_viewDates[i * days + j]);
					}

					weeks.push(dates);
				}
			}
		}
	};

	return [
		weeks,
		_viewDates,
		_viewClass,
		dayMaxEvents,
		hiddenDays,
		_hiddenEvents,
		days,
		$_viewDates,
		$dayMaxEvents,
		$hiddenDays
	];
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		options.dayMaxEvents = false;
		options.dayPopoverFormat = {month: 'long', day: 'numeric', year: 'numeric'};
		options.moreLinkContent = undefined;
		// Common options
		options.buttonText.dayGridMonth = 'month';
		options.theme.uniform = 'ec-uniform';
		options.theme.dayFoot = 'ec-day-foot';
		options.theme.month = 'ec-month';
		options.theme.popup = 'ec-popup';
		options.view = 'dayGridMonth';
		options.views.dayGridMonth = {
			component: View,
			dayHeaderFormat: {weekday: 'short'},
			displayEventEnd: false,
			duration: {months: 1},
			monthMode: true,
			titleFormat: {year: 'numeric', month: 'long'}
		};
	},

	createStores(state) {
		state._intlDayPopover = intl(state.locale, state.dayPopoverFormat);
	}
};

export { index as default, prepareEventChunks };
